<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Contact Messages List</h4>
        </div>

        <div>
          <general-data-table
            ref="table"
            :api-url="'contact-us/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/checkAccess']('contact-us/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Message?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";


export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '100', align: 'center'},
        {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'Website', value: 'website', minWidth: '100', align: 'center'},
        {label: 'Message', value: 'message', minWidth: '400', align: 'center'},
        {label: 'Date', value: 'created_at', minWidth: '200', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL
    }

  },

  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      this.axios.delete("contact-us/delete/" + this.toDeleteId).then((response) => {

        this.$notify({
          message: "Message deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    }
  },
}
</script>
<style>
</style>
